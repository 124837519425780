import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { EnvironmentsSummaryResource, TagSetResource, TenantResource, WorkerPoolsSummaryResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { sum } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { WorkerContent } from "~/areas/infrastructure/components/InfrastructureDashboard/WorkersCard/WorkerContent";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { PageContent } from "~/components/PageContent/PageContent";
import { PageLoading } from "~/components/PageContent/PageLoading";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "~/components/tenantTagsets";
import { EnvironmentsContent } from "./EnvironmentsCard/EnvironmentsContent";
import { HealthStatusContent } from "./HealthStatusCard/HealthStatusContent";
import { InfraOverviewCard } from "./InfraOverviewCard";
import { MachinesContent } from "./MachinesCard/MachinesContent";
import Onboarding from "./Onboarding";
import { RolesContent } from "./RolesCard/RolesContent";
import { TagSetsContent } from "./TagSetsCard/TagSetsContent";
import { TenantsContent } from "./TenantsCard/TenantsContent";
import { WorkerPoolsContent } from "./WorkerPoolsCard/WorkerPoolsContent";
interface InfrastructureDashboardState extends DataBaseComponentState {
    filterPartialName: string;
    isSearching: boolean;
    environmentsSummaryResource: EnvironmentsSummaryResource | undefined;
}
type TenantResourceGroup = {
    environmentsSummaryResource: EnvironmentsSummaryResource | undefined;
    tenantResources: TenantResource[];
};
type TagSetsResourceGroup = {
    environmentsSummaryResource: EnvironmentsSummaryResource | undefined;
    tagSetsResources: TagSetResource[];
};
interface InitialData {
    environmentsSummaryPromise: Promise<EnvironmentsSummaryResource> | undefined;
    workerpoolsSummaryPromise: Promise<WorkerPoolsSummaryResource> | undefined;
    machineRolesPromise: Promise<string[]> | undefined;
    tenantResourceGroupPromises: Promise<TenantResourceGroup>;
    tagSetsResourceGroupPromises: Promise<TagSetsResourceGroup>;
    showOnboarding: boolean;
}
interface InfrastructureDashboardProps {
    initialData: InitialData;
    spaceId: string;
}
interface GlobalConnectedProps extends InfrastructureDashboardProps {
    isMultiTenancyEnabled: boolean;
}
const InfrastructureDashboardFormPage = FormPage<InitialData>();
const Title = "Overview";
const maxNumberOfItemsToShowInCards = 50;
interface InfrastructureDashboardPageProps {
    spaceId: string;
}
const InfrastructureDashboardPage: React.FC<InfrastructureDashboardPageProps> = ({ spaceId }) => {
    const showOnboarding = async () => {
        const noEnvironmentsPromise = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? repository.Environments.list({ take: 0 }).then((v) => v.TotalResults === 0) : Promise.resolve(false);
        const noWorkersPromise = isAllowed({ permission: Permission.WorkerView }) ? repository.Workers.list({ take: 0 }).then((v) => v.TotalResults === 0) : Promise.resolve(false);
        const noEnvironments = await noEnvironmentsPromise;
        const noWorkers = await noWorkersPromise;
        return noEnvironments && noWorkers;
    };
    return (<InfrastructureDashboardFormPage title={Title} load={async () => {
            const environmentsSummaryPromise = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? repository.Environments.summary() : undefined;
            const workerpoolsSummaryPromise = isAllowed({ permission: Permission.WorkerView }) ? repository.WorkerPools.summary() : undefined;
            const machineRolesPromise = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? repository.MachineRoles.all() : undefined;
            const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
            const tenantResourceGroupPromises = (async function loadTenantsResources() {
                return {
                    tenantResources: await tenantsPromise,
                    environmentsSummaryResource: await environmentsSummaryPromise,
                };
            })();
            const tagSetsResourceGroupPromises = (async function loadTagSetsPromise() {
                return {
                    tagSetsResources: await tenantTagsets.getAll(),
                    environmentsSummaryResource: await environmentsSummaryPromise,
                };
            })();
            return {
                environmentsSummaryPromise,
                workerpoolsSummaryPromise,
                machineRolesPromise,
                tenantResourceGroupPromises,
                tagSetsResourceGroupPromises,
                showOnboarding: await showOnboarding(),
            };
        }} renderWhenLoaded={(data) => <InfrastructureDashboard initialData={data} spaceId={spaceId}/>} renderAlternate={() => <PageLoading loadingTitle={Title}/>}/>);
};
InfrastructureDashboardPage.displayName = "InfrastructureDashboardPage"
class InfrastructureDashboardInner extends DataBaseComponent<GlobalConnectedProps, InfrastructureDashboardState> {
    constructor(props: GlobalConnectedProps) {
        super(props);
        this.state = {
            isSearching: false,
            filterPartialName: "",
            environmentsSummaryResource: undefined,
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const resource = await this.props.initialData.environmentsSummaryPromise;
            if (resource !== undefined) {
                this.setState({
                    environmentsSummaryResource: resource,
                });
            }
        });
    }
    render() {
        const hasNoEnvironment = this.state.environmentsSummaryResource !== undefined && this.state.environmentsSummaryResource.EnvironmentSummaries.length === 0;
        return (<PageContent pageLayoutV2={true} header={{ title: Title, pageLayoutV2: true }} busy={this.state.busy} errors={this.errors}>
                {this.props.initialData.showOnboarding ? (<Onboarding spaceId={this.props.spaceId} hasNoEnvironment={hasNoEnvironment}/>) : (<div className={cardsContainerStyles}>
                        {this.props.initialData.environmentsSummaryPromise && (<InfraOverviewCard<EnvironmentsSummaryResource> key="environmentsCard" title="Environments" getCount={(resource: EnvironmentsSummaryResource) => resource.EnvironmentSummaries.length} link={links.infrastructureEnvironmentsPage.generateUrl({ spaceId: this.props.spaceId })} dataPromise={this.props.initialData.environmentsSummaryPromise} hasContent={(resource: EnvironmentsSummaryResource) => resource.EnvironmentSummaries.length > 0} renderContent={(summaryResource: EnvironmentsSummaryResource) => <EnvironmentsContent summaryResource={summaryResource} numberOfEnvironmentsToRender={maxNumberOfItemsToShowInCards} spaceId={this.props.spaceId}/>}/>)}
                        {this.props.initialData.environmentsSummaryPromise && (<InfraOverviewCard<EnvironmentsSummaryResource> key="machinesCard" title="Deployment Targets" link={links.deploymentTargetsPage.generateUrl({ spaceId: this.props.spaceId })} getCount={(resource: EnvironmentsSummaryResource) => resource.TotalMachines} dataPromise={this.props.initialData.environmentsSummaryPromise} hasContent={(resource: EnvironmentsSummaryResource) => resource.TotalMachines > 0} renderContent={(summaryResource: EnvironmentsSummaryResource) => (<MachinesContent link={(filter) => links.deploymentTargetsPage.generateUrl({ spaceId: this.props.spaceId }, filter)} summaryResource={summaryResource} doBusyTask={this.doBusyTask}/>)}/>)}

                        {this.props.initialData.environmentsSummaryPromise && (<InfraOverviewCard<EnvironmentsSummaryResource> key="targetHealthStatusCard" title="Target Status" link={links.deploymentTargetsPage.generateUrl({ spaceId: this.props.spaceId })} getCount={(resource: EnvironmentsSummaryResource) => sum(Object.values(resource.MachineHealthStatusSummaries))} dataPromise={this.props.initialData.environmentsSummaryPromise} hasContent={(resource: EnvironmentsSummaryResource) => resource.TotalMachines > 0} renderContent={(summaryResource: EnvironmentsSummaryResource) => (<HealthStatusContent link={(filter) => links.deploymentTargetsPage.generateUrl({ spaceId: this.props.spaceId }, filter)} summaryResource={summaryResource}/>)}/>)}
                        {this.props.initialData.machineRolesPromise && (<InfraOverviewCard<string[]> key="rolesCard" title="Target Tags" link={links.deploymentTargetsPage.generateUrl({ spaceId: this.props.spaceId })} getCount={(resource: string[]) => resource.length} dataPromise={this.props.initialData.machineRolesPromise} hasContent={(resource: string[]) => resource.length > 0} renderContent={(summaryResource: string[]) => (<RolesContent machineRoles={summaryResource} link={(filter) => links.deploymentTargetsPage.generateUrl({ spaceId: this.props.spaceId }, filter)} numberOfItemsToRender={maxNumberOfItemsToShowInCards}/>)}/>)}
                        {this.props.initialData.workerpoolsSummaryPromise && (<InfraOverviewCard<WorkerPoolsSummaryResource> key="workersCard" title="Worker Pools" link={links.workerPoolsPage.generateUrl({ spaceId: this.props.spaceId })} getCount={(resource: WorkerPoolsSummaryResource) => resource.WorkerPoolSummaries.length} dataPromise={this.props.initialData.workerpoolsSummaryPromise} hasContent={(resource: WorkerPoolsSummaryResource) => resource.WorkerPoolSummaries.length > 0} renderContent={(summaryResource: WorkerPoolsSummaryResource) => <WorkerPoolsContent summaryResource={summaryResource} numberOfItemsToRender={maxNumberOfItemsToShowInCards} spaceId={this.props.spaceId}/>}/>)}
                        {this.props.initialData.workerpoolsSummaryPromise && (<InfraOverviewCard<WorkerPoolsSummaryResource> key="workersMachinesCard" title="Workers" link={links.workerMachinesPage.generateUrl({ spaceId: this.props.spaceId })} getCount={(resource: WorkerPoolsSummaryResource) => sum(Object.values(resource.MachineEndpointSummaries))} dataPromise={this.props.initialData.workerpoolsSummaryPromise} hasContent={(resource: WorkerPoolsSummaryResource) => resource.TotalMachines > 0} renderContent={(summaryResource: WorkerPoolsSummaryResource) => (<WorkerContent link={(filter) => links.workerMachinesPage.generateUrl({ spaceId: this.props.spaceId }, filter)} summaryResource={summaryResource} doBusyTask={this.doBusyTask}/>)}/>)}
                        {this.props.initialData.workerpoolsSummaryPromise && (<InfraOverviewCard<WorkerPoolsSummaryResource> key="workerHealthStatusCard" title="Worker Status" link={links.workerMachinesPage.generateUrl({ spaceId: this.props.spaceId })} getCount={(resource: WorkerPoolsSummaryResource) => sum(Object.values(resource.MachineHealthStatusSummaries))} dataPromise={this.props.initialData.workerpoolsSummaryPromise} hasContent={(resource: WorkerPoolsSummaryResource) => resource.WorkerPoolSummaries.length > 0} renderContent={(summaryResource: WorkerPoolsSummaryResource) => (<HealthStatusContent link={(filter) => links.workerMachinesPage.generateUrl({ spaceId: this.props.spaceId }, filter)} summaryResource={summaryResource}/>)}/>)}
                        {this.props.initialData.tagSetsResourceGroupPromises &&
                    this.props.isMultiTenancyEnabled &&
                    isAllowed({
                        permission: Permission.TenantView,
                        tenant: "*",
                    }) && (<InfraOverviewCard<TagSetsResourceGroup> key="tagSetsCard" title="Tenant Tag Sets" link={links.tagSetsPage.generateUrl({ spaceId: this.props.spaceId })} getCount={(resource: TagSetsResourceGroup) => resource.tagSetsResources.length} dataPromise={this.props.initialData.tagSetsResourceGroupPromises} hasContent={(resource: TagSetsResourceGroup) => resource.tagSetsResources.length > 0} renderContent={(summaryResource: TagSetsResourceGroup) => (<TagSetsContent spaceId={this.props.spaceId} tagSummaries={summaryResource.environmentsSummaryResource?.MachineTenantTagSummaries} summaryResource={summaryResource.tagSetsResources} numberOfItemsToRender={maxNumberOfItemsToShowInCards}/>)}/>)}
                        {this.props.initialData.tenantResourceGroupPromises &&
                    this.props.isMultiTenancyEnabled &&
                    isAllowed({
                        permission: Permission.TenantView,
                        tenant: "*",
                    }) && (<InfraOverviewCard<TenantResourceGroup> key="tenantsCard" title="Tenants" link={links.tenantsPage.generateUrl({ spaceId: this.props.spaceId })} getCount={(resource: TenantResourceGroup) => resource.tenantResources?.length} dataPromise={this.props.initialData.tenantResourceGroupPromises} hasContent={(resource: TenantResourceGroup) => resource.tenantResources.length > 0} renderContent={(summaryResource: TenantResourceGroup) => (<TenantsContent spaceId={this.props.spaceId} tenantsResource={summaryResource.tenantResources} tenantSummaries={summaryResource.environmentsSummaryResource?.MachineTenantSummaries} numberOfItemsToRender={maxNumberOfItemsToShowInCards}/>)}/>)}
                    </div>)}
            </PageContent>);
    }
    static displayName = "InfrastructureDashboardInner";
}
const mapGlobalStateToProps = (state: GlobalState, props: InfrastructureDashboardProps): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
        initialData: props.initialData,
        spaceId: props.spaceId,
    };
};
const InfrastructureDashboard = connect(mapGlobalStateToProps)(InfrastructureDashboardInner);
export default InfrastructureDashboardPage;
const cardsContainerStyles = css({
    display: "flex",
    gap: space[32],
    flexWrap: "wrap",
});
