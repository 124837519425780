import { css } from "@emotion/css";
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { Pagination } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ApiOperationStatus } from "@octopusdeploy/octopus-react-client";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import type { DashboardConfigurationResource, LiveStatusDashboardFilter, ProjectsDashboardFilter, UserFavouriteProjectSummary } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { useState, useCallback } from "react";
import * as React from "react";
import { DashboardProjectGroups, ProjectGroupDeploymentsSkeleton } from "~/areas/dashboard/DashboardOverview/DashboardProjectGroups";
import type { FavouriteProjectsData } from "~/areas/projects/components/DashboardDataSource/DataSet";
import { ProjectsDashboardLiveStatusSwitch } from "~/areas/projects/components/Projects/ProjectsDashboardLiveStatusSwitch";
import { repository, session } from "~/clientInstance";
import { getErrorsFromError } from "~/components/DataBaseComponent/Errors";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { PageContent } from "~/components/PageContent/PageContent";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import useLocalStorage from "~/hooks/useLocalStorage";
import type { ProjectStatus } from "../ProjectStatus/useProjectStatus";
import { ProjectsDashboardAdvancedFilters, ProjectsDashboardAdvancedFiltersSkeleton } from "./ProjectsDashboardAdvancedFilters";
import { ProjectsDashboardSearchBox, ProjectsDashboardSearchBoxSkeleton } from "./ProjectsDashboardSearchBox";
import { Skeleton } from "./Skeleton";
export interface ProjectsFilter {
    page: number;
    pageSize: ProjectsPageSize;
    searchValue: string;
}
export const projectsPageSizes = [50, 100, 150] as const;
export type ProjectsPageSize = (typeof projectsPageSizes)[number];
export const defaultProjectsPageSize: ProjectsPageSize = 50;
interface ProjectsDashboardPageContentProps {
    spaceId: string;
    pageActions: PageAction[];
    primaryPageAction: PrimaryPageAction;
    doBusyTaskStatus: ApiOperationStatus;
    dashboardConfiguration: DashboardConfigurationResource;
    filter: ProjectsFilter;
    setFilter: (newFilter: ProjectsFilter) => void;
    onAddProjectToGroup: (projectGroupId: string) => void;
    projectStatus?: ProjectStatus;
}
export function ProjectsDashboardPageContent({ spaceId, pageActions, primaryPageAction, doBusyTaskStatus, dashboardConfiguration, filter, setFilter, onAddProjectToGroup, projectStatus }: ProjectsDashboardPageContentProps) {
    const trackEvent = useTrackEvent();
    const isKubernetesLiveStatusEnabled = isFeatureToggleEnabled("KubernetesLiveObjectStatusFeatureToggle");
    const [showLiveStatus, setShowLiveStatus] = useLocalStorage("Octopus.Dashboard.ShowLiveStatus", false);
    const { isLoading: isLoadingInitialDashboard, result: initialDashboardData, refetch: refetchInitialDashboard, error: initialDashboardError } = useInitialDashboardQuery(getDashboardFiltersQueryParams(filter));
    const { isLoading: isLoadingDetailedDashboard, result: detailedDashboardData, refetch: refetchDetailedDashboard, error: detailedDashboardError } = useDetailedDashboardQuery(getDashboardFiltersQueryParams(filter));
    const { result: liveStatusDashboardData, refetch: refetchLiveStatusDashboard, error: liveStatusDashboardError } = useLiveStatusQuery(getLiveStatusDashboardFiltersQueryParams(filter), isKubernetesLiveStatusEnabled && showLiveStatus);
    const favouriteProjectsData = useFavouriteData();
    const dashboardData = (isLoadingDetailedDashboard ? null : detailedDashboardData) ?? initialDashboardData;
    const onSearchValueChanged = (newValue: string) => {
        setFilter({ ...filter, searchValue: newValue, page: 1 });
    };
    const onPageChange = (page: number) => {
        setFilter({ ...filter, page });
    };
    const onPageSizeChange = (pageSize: number) => {
        setFilter({ ...filter, page: 1, pageSize: isProjectsPageSize(pageSize) ? pageSize : defaultProjectsPageSize });
        trackEvent(createPageSizeChangedEvent(pageSize));
    };
    const onShowLiveStatusChanged = (value: boolean) => {
        setShowLiveStatus(value);
    };
    const onAdvancedFiltersChanged = () => {
        setFilter({ ...filter, page: 1 });
        refetchInitialDashboard();
        refetchDetailedDashboard();
        refetchLiveStatusDashboard();
    };
    const loadingErrors = [initialDashboardError, detailedDashboardError, liveStatusDashboardError].filter((error) => error !== null);
    if (dashboardData === null) {
        const showInitialLoadingSkeleton = !initialDashboardError && !detailedDashboardError;
        return (<PageContent header={{
                title: "Projects",
                pageActions: pageActions,
                primaryAction: primaryPageAction,
            }} filters={showInitialLoadingSkeleton
                ? {
                    inputs: [<ProjectsDashboardSearchBoxSkeleton key="search-box"/>, <ProjectsDashboardAdvancedFiltersSkeleton key="advanced-filters"/>],
                }
                : undefined} pagination={showInitialLoadingSkeleton
                ? {
                    ui: <PaginationSkeleton />,
                    placement: "top",
                }
                : undefined}>
                <div className={projectsDashboardStyles}>
                    {loadingErrors.map((error, index) => (<DashboardErrorPanel error={error} key={index}/>))}
                    {showInitialLoadingSkeleton && <ProjectGroupDeploymentsSkeleton />}
                </div>
            </PageContent>);
    }
    const { TotalResults, ItemsPerPage, ProjectGroups } = dashboardData;
    const pagination = <Pagination label="Projects" totalResults={TotalResults} itemsPerPageOptions={projectsPageSizes} selectedItemsPerPage={ItemsPerPage} currentPage={filter.page} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange}/>;
    //We shouldn't show the skeleton when loading the detailed data, so we only show this when both the initial and
    //detailed data is being reloaded. Currently, this will happen whenever the advanced filter is changed or if changing
    //filters such as the page number / search.
    const showSkeletonLoading = isLoadingDetailedDashboard && isLoadingInitialDashboard;
    return (<PageContent header={{
            title: "Projects",
            pageActions: pageActions,
            primaryAction: primaryPageAction,
        }} filters={{
            inputs: [
                <ProjectsDashboardSearchBox key="search-box" searchValue={filter.searchValue} onSearchValueChange={onSearchValueChanged}/>,
                <ProjectsDashboardAdvancedFilters key="advanced-filters" initialDashboardConfiguration={dashboardConfiguration} onAdvancedFilterChange={onAdvancedFiltersChanged}/>,
                <ProjectsDashboardLiveStatusSwitch key="show-live-status" value={showLiveStatus} onValueChange={onShowLiveStatusChanged} accessibleName="show live status"/>,
            ],
        }} pagination={{
            ui: pagination,
            placement: showSkeletonLoading ? "top" : "topAndBottom",
        }} legacyStatus={doBusyTaskStatus}>
            {loadingErrors.map((error, index) => (<DashboardErrorPanel error={error} key={index}/>))}
            {showSkeletonLoading ? (<ProjectGroupDeploymentsSkeleton />) : (<DashboardProjectGroups projectGroups={ProjectGroups} favouriteProjectsData={favouriteProjectsData} spaceId={spaceId} onAddProjectToGroup={onAddProjectToGroup} projectStatus={projectStatus} showLiveStatus={showLiveStatus} projectGroupsLiveStatus={liveStatusDashboardData?.ProjectGroups ?? []}/>)}
        </PageContent>);
}
function DashboardErrorPanel({ error }: {
    error: Error;
}) {
    const convertedError = getErrorsFromError(error);
    return <ErrorPanel message={convertedError.message} errors={convertedError.errors} parsedHelpLinks={convertedError.parsedHelpLinks} helpText={convertedError.helpText} helpLink={convertedError.helpLink} statusCode={convertedError.statusCode}/>;
}
interface PageSizeChangedEvent extends AnalyticsEvent<"Projects Dashboard Pagination: User Changed PageSize"> {
    pageSize: number;
}
function createPageSizeChangedEvent(pageSize: number): PageSizeChangedEvent {
    return {
        eventName: "Projects Dashboard Pagination: User Changed PageSize",
        pageSize,
    };
}
function PaginationSkeleton() {
    return (<div className={paginationSkeletonStyles}>
            <Skeleton shape={"Rounded"} borderRadius={"medium"}/>
        </div>);
}
const paginationSkeletonStyles = css({
    height: "2.25rem",
    width: "17.25rem",
});
const projectsDashboardStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[24],
});
function useFavouriteData(): FavouriteProjectsData {
    const [favouriteProjects, setFavourites] = useState<UserFavouriteProjectSummary[]>([]);
    // This doBusyTask is intentionally independent of the one created from the ProjectsPage.
    // The status of these data access operations are not shown in the UI.
    // This doBusyTask will go away when the below changes to the data access hooks.
    const { doBusyTask } = useLegacyDoBusyTask();
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (hasPermission(Permission.ProjectView)) {
            const result = await repository.Users.getProjectFavourites();
            setFavourites(result.Projects);
        }
        else {
            setFavourites([]);
        }
    }, []);
    const onFavouriteProjectChanged = useCallback((projectId: string, isFavourite: boolean) => doBusyTask(async () => {
        if (isFavourite) {
            const { Project } = await repository.Users.createProjectFavourite(projectId);
            setFavourites((prev) => [...prev, Project]);
        }
        else {
            await repository.Users.deleteProjectFavourite(projectId);
            setFavourites((prev) => prev.filter((p) => p.Id !== projectId));
        }
    }), [doBusyTask]);
    return { favouriteProjects, onFavouriteProjectChanged };
}
function useInitialDashboardQuery(dashboardFilters: ProjectsDashboardFilter) {
    const { skip, take, projectOrGroupPartialName } = dashboardFilters;
    return useInlineStatusQuery(async (repository) => repository.Dashboards.getInitialProjectsDashboard({ skip, take, projectOrGroupPartialName }), [skip, take, projectOrGroupPartialName], "Initial Dashboard Data");
}
function useDetailedDashboardQuery({ skip, take, projectOrGroupPartialName }: ProjectsDashboardFilter) {
    return useInlineStatusQuery(async (repository) => {
        if (session.features?.IsProjectsPageOptimizationEnabled ?? false) {
            return repository.Dashboards.getProjectsPage({ skip, take, projectOrGroupPartialName });
        }
        return repository.Dashboards.getDetailedProjectsDashboard({ skip, take, projectOrGroupPartialName });
    }, [skip, take, projectOrGroupPartialName], "Detailed Dashboard Data", { refetchIntervalInMs: dashboardRefreshIntervalInMs });
}
function useLiveStatusQuery(dashboardFilters: LiveStatusDashboardFilter, shouldQuery: boolean) {
    const { projectOrGroupPartialName } = dashboardFilters;
    return useInlineStatusQuery(async (repository) => {
        if (!shouldQuery) {
            return {
                ProjectGroups: [],
            };
        }
        return repository.Dashboards.getProjectsLiveStatus({ projectOrGroupPartialName });
    }, [projectOrGroupPartialName, shouldQuery], "Live Status Data", {
        refetchIntervalInMs: shouldQuery ? dashboardRefreshIntervalInMs : undefined,
    });
}
const dashboardRefreshIntervalInMs = 6000;
function getDashboardFiltersQueryParams(filters: ProjectsFilter) {
    const skip = (filters.page - 1) * filters.pageSize;
    const take = filters.pageSize;
    const projectOrGroupPartialName = filters.searchValue?.toLowerCase() ?? "";
    return { skip, take, projectOrGroupPartialName };
}
function getLiveStatusDashboardFiltersQueryParams(filters: ProjectsFilter) {
    const projectOrGroupPartialName = filters.searchValue?.toLowerCase() ?? "";
    return { projectOrGroupPartialName };
}
export function isProjectsPageSize(pageSize: number | undefined): pageSize is ProjectsPageSize {
    if (pageSize === undefined)
        return false;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (projectsPageSizes as readonly number[]).includes(pageSize);
}
