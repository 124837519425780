import type { ButtonMenuItem, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { Switch } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, FeatureToggleResource, TenantResource, FeatureToggleEnvironmentResource, CreateFeatureToggleCommand, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useState } from "react";
import EnvironmentOrMissingChip from "~/areas/projects/components/FeatureToggles/EnvironmentOrMissingChip";
import FeatureToggleTargetsButton from "~/areas/projects/components/FeatureToggles/FeatureToggleTargetsButton";
import { FeatureTogglesDataTableNameCell } from "~/areas/projects/components/FeatureToggles/FeatureTogglesDataTableNameCell";
import PreviewClientIdentifierDialog from "~/areas/projects/components/FeatureToggles/PreviewClientIdentifierDialog";
import RotateClientIdentifierDialog from "~/areas/projects/components/FeatureToggles/RotateClientIdentifierDialog";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { GroupedDataTable } from "~/components/GroupedDataTable/GroupedDataTable";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { PageContent } from "~/components/PageContent/PageContent";
import Text from "~/primitiveComponents/form/Text/Text";
export class FeatureTogglesPage extends DataBaseComponent<{}> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    render() {
        return <FeatureTogglesLayout doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "FeatureTogglesPage";
}
interface FeatureTogglesLayoutProps {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
}
interface FeatureToggleState {
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    featureToggles: FeatureToggleResource[];
    project: ProjectResource;
}
function FeatureTogglesLayout({ doBusyTask, busy, errors }: FeatureTogglesLayoutProps) {
    const projectContext = useProjectContext();
    const project = projectContext.state && projectContext.state.model;
    const [data, setData] = useState<FeatureToggleState | null>(null);
    const { navigate } = useSpaceAwareNavigation();
    const { isOpen: isAddToggleDialogOpen, openDialog: openAddToggleDialog } = useDialogTrigger();
    const { isOpen: isPreviewDialogOpen, openDialog: openPreviewDialog } = useDialogTrigger();
    const { isOpen: isRotateDialogOpen, openDialog: openRotateDialog } = useDialogTrigger();
    useDoBusyTaskEffect(doBusyTask, async () => {
        const featureTogglesResponsePromise = repository.FeatureToggles.list(projectContext.state.model);
        const environmentsPromise = repository.Environments.all();
        const featureTogglesResponse = await featureTogglesResponsePromise;
        // Fetch all the tenants that are configured on toggles. Because we don't need to select tenants on this page, we can avoid tenants.all()
        const tenantIds = featureTogglesResponse.FeatureToggles.flatMap((f) => f.Environments)
            .flatMap((t) => t.TenantIds)
            .reduce((acc, tenantId) => acc.add(tenantId), new Set<string>());
        const excludedTenantIds = featureTogglesResponse.FeatureToggles.flatMap((f) => f.Environments)
            .flatMap((t) => t.ExcludedTenantIds)
            .reduce((acc, tenantId) => acc.add(tenantId), new Set<string>());
        const tenants = await repository.Tenants.all({ ids: [...tenantIds, ...excludedTenantIds] });
        const sortedFeatureToggles = featureTogglesResponse.FeatureToggles.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        setData({
            environments: await environmentsPromise,
            tenants,
            featureToggles: sortedFeatureToggles,
            project,
        });
    }, []);
    const addToggleAction: PrimaryPageAction = {
        type: "button",
        label: "Add Feature Toggle",
        onClick: openAddToggleDialog,
    };
    const previewClientIdentifierAction: ButtonMenuItem = {
        type: "button",
        label: "Preview Client Identifiers",
        onClick: openPreviewDialog,
    };
    const rotateClientIdentifierAction: ButtonMenuItem = {
        type: "button",
        label: "Rotate Client Identifiers",
        onClick: openRotateDialog,
    };
    return (<PageContent header={{ title: "Feature Toggles", primaryAction: addToggleAction, overflowActions: [previewClientIdentifierAction, rotateClientIdentifierAction] }} fullWidth={true} busy={busy} errors={errors}>
            <Dialog open={isAddToggleDialogOpen}>
                <AddFeatureToggle project={project} onOk={(toggle) => {
            navigate(links.featureTogglePage.generateUrl({ spaceId: toggle.SpaceId, projectSlug: project.Slug, featureToggleSlug: toggle.Slug }));
        }}/>
            </Dialog>
            {data && (<Dialog open={isPreviewDialogOpen}>
                    <PreviewClientIdentifierDialog doBusyTask={doBusyTask} busy={busy} errors={errors} environments={data.environments}/>
                </Dialog>)}
            {data && (<Dialog open={isRotateDialogOpen}>
                    <RotateClientIdentifierDialog doBusyTask={doBusyTask} busy={busy} errors={errors} environments={data.environments}/>
                </Dialog>)}
            {data && <FeatureToggleTable featureToggles={data.featureToggles} tenants={data.tenants} environments={data.environments} project={data.project}/>}
        </PageContent>);
}
interface FeatureToggleEnvironmentRow {
    FeatureToggle: FeatureToggleResource;
    Environment?: FeatureToggleEnvironmentResource;
    RowKey: string;
    IsChild: boolean;
}
function FeatureToggleTable({ featureToggles, tenants, environments, project }: FeatureToggleState) {
    const environmentRow = (featureToggle: FeatureToggleResource, featureToggleEnvironment: FeatureToggleEnvironmentResource) => (<div>
            <EnvironmentOrMissingChip environmentId={featureToggleEnvironment.DeploymentEnvironmentId} environments={environments}/>
            <FeatureToggleTargetsButton project={project} featureToggle={featureToggle} featureToggleEnvironment={featureToggleEnvironment} environments={environments} tenants={tenants}/>
        </div>);
    function buildToggleRows(featureToggles: FeatureToggleResource[]): FeatureToggleEnvironmentRow[] {
        const rows: FeatureToggleEnvironmentRow[] = [];
        for (const featureToggle of featureToggles) {
            const environments = featureToggle.Environments;
            let isChild = false;
            for (const toggle of environments) {
                rows.push({
                    FeatureToggle: featureToggle,
                    Environment: toggle,
                    RowKey: `${featureToggle.Id}${toggle.DeploymentEnvironmentId}`,
                    IsChild: isChild,
                });
                isChild = true;
            }
            // IF there are no environments defined, then push a row with just the feature toggle name
            if (environments.length === 0) {
                rows.push({
                    FeatureToggle: featureToggle,
                    RowKey: `${featureToggle.Id}`,
                    IsChild: false,
                });
            }
        }
        return rows;
    }
    return (<GroupedDataTable data={buildToggleRows(featureToggles)} columns={[
            {
                title: "Name",
                width: "25%",
                render: (data) => (data.FeatureToggle && !data.IsChild ? <FeatureTogglesDataTableNameCell featureToggle={data.FeatureToggle}/> : <></>),
                isChild: (data) => data.IsChild,
            },
            {
                title: "Status",
                width: "10%",
                render: (data) => (data.Environment ? <Switch value={data.Environment.IsEnabled} label={data.Environment.IsEnabled ? "On" : "Off"} disabled={true}/> : <div />),
            },
            {
                title: "Environment",
                render: (data) => (data.Environment ? environmentRow(data.FeatureToggle, data.Environment) : <div />),
            },
        ]} getRowKey={(data) => data.RowKey}/>);
}
interface AddFeatureToggleProps {
    project: Readonly<ProjectResource>;
    onOk(featureToggleResource: FeatureToggleResource): void;
}
class AddFeatureToggle extends DataBaseComponent<AddFeatureToggleProps, {
    name: string;
}> {
    constructor(props: AddFeatureToggleProps) {
        super(props);
        this.state = { name: "" };
    }
    async onOk() {
        await this.doBusyTask(async () => {
            const newFeatureToggle: CreateFeatureToggleCommand = { Name: this.state.name, ProjectId: this.props.project.Id, Environments: [] };
            const createResponse = await repository.FeatureToggles.create(this.props.project, newFeatureToggle);
            return this.props.onOk(createResponse);
        });
    }
    render() {
        return (<OkDialogLayout title="Add Feature Toggle" busy={this.state.busy} errors={this.errors} okButtonDisabled={!this.state.name} onOkClick={() => this.onOk()}>
                <Text value={this.state.name} onChange={(value) => this.setState({ name: value })} label="Name" autoFocus/>
            </OkDialogLayout>);
    }
    static displayName = "AddFeatureToggle";
}
