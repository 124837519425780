/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Level2OuterPageLayout } from "@octopusdeploy/design-system-components";
import type { TelemetryConfigurationResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import moment from "moment";
import * as React from "react";
import { client, repository, session } from "~/clientInstance";
import { NewFeatureChip } from "~/components/Chips";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useCreatePageSideNavLink } from "~/hooks/useCreatePageSideNavLink";
import { TelemetryHasBeenIntroducedLocalStorageKey } from "../Telemetry/TelemetryPage";
interface ConfigurationLayoutState {
    hideAnyAuthenticationProvidersSupportPasswordManagement: boolean;
    telemetryConfiguration: TelemetryConfigurationResource | undefined;
}
interface ConfigurationLayoutProps {
    children: React.ReactNode;
}
export class ConfigurationLayout extends DataBaseComponent<ConfigurationLayoutProps, ConfigurationLayoutState> {
    constructor(props: ConfigurationLayoutProps) {
        super(props);
        this.state = {
            // flipped to 'hide' so it'll just disappear in the case where it's not supported
            // instead of showing up after the data fetch, and without delaying display of whole menu for data load
            hideAnyAuthenticationProvidersSupportPasswordManagement: false,
            telemetryConfiguration: undefined,
        };
    }
    componentDidMount() {
        this.doBusyTask(async () => {
            const telemetryConfiguration = isAllowed({ permission: Permission.AdministerSystem }) ? repository.TelemetryConfiguration.get() : Promise.resolve(undefined);
            const document = await repository.Authentication.get();
            this.setState({
                hideAnyAuthenticationProvidersSupportPasswordManagement: !document.AnyAuthenticationProvidersSupportPasswordManagement,
                telemetryConfiguration: await telemetryConfiguration,
            });
        });
    }
    render() {
        //TODO: Busy state and errors aren't currently being observed for the top level layout at the moment.
        return (<ConfigurationLayoutInternal telemetryConfiguration={this.state.telemetryConfiguration} hideAnyAuthenticationProvidersSupportPasswordManagement={this.state.hideAnyAuthenticationProvidersSupportPasswordManagement}>
                {this.props.children}
            </ConfigurationLayoutInternal>);
    }
    static displayName = "ConfigurationLayout";
}
interface ConfigurationLayoutInternalProps {
    children: React.ReactNode;
    hideAnyAuthenticationProvidersSupportPasswordManagement: boolean;
    telemetryConfiguration: TelemetryConfigurationResource | undefined;
}
function ConfigurationLayoutInternal({ children, telemetryConfiguration, hideAnyAuthenticationProvidersSupportPasswordManagement }: ConfigurationLayoutInternalProps) {
    const navItems = useConfigurationNavLinks(telemetryConfiguration, hideAnyAuthenticationProvidersSupportPasswordManagement);
    return (<main>
            <Level2OuterPageLayout navItems={navItems} header={{ title: "Configuration" }}>
                {children}
            </Level2OuterPageLayout>
        </main>);
}
function useConfigurationNavLinks(telemetryConfiguration: TelemetryConfigurationResource | undefined, hideAnyAuthenticationProvidersSupportPasswordManagement: boolean) {
    const createPageSideNavLink = useCreatePageSideNavLink();
    const showAsNew = telemetryConfiguration?.ShowAsNewUntil ? moment(telemetryConfiguration.ShowAsNewUntil).isAfter(moment()) : false;
    const telemetryHasBeenIntroduced = localStorage.getItem(TelemetryHasBeenIntroducedLocalStorageKey) === "true";
    const gitMenuPermissions = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle") ? undefined : { permission: [Permission.AdministerSystem, Permission.ConfigureServer] };
    return compact([
        createPageSideNavLink("Audit", links.auditPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.EventView], wildcard: true }),
        createPageSideNavLink("Backup", links.backupPage.generateUrl(), undefined, { permission: Permission.AdministerSystem }),
        isFeatureToggleEnabled("DeploymentFreezeFeatureToggle") ? createPageSideNavLink("Deployment Freezes", links.deploymentFreezesListPage.generateUrl(), undefined, { permission: Permission.DeploymentFreezeAdminister }) : undefined,
        createPageSideNavLink("Diagnostics", links.diagnosticsPage.generateUrl(), undefined, { permission: Permission.AdministerSystem }),
        createPageSideNavLink("Features", links.featuresPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.ConfigureServer] }),
        createPageSideNavLink("Git", links.gitPage.generateUrl(), undefined, gitMenuPermissions),
        client.getSystemLink((link) => link.LetsEncryptConfiguration) ? createPageSideNavLink("Let's Encrypt", links.letsEncryptPage.generateUrl(), undefined, { permission: Permission.AdministerSystem }) : null,
        createPageSideNavLink("License", links.licensePage.generateUrl(), true, { permission: Permission.AdministerSystem }),
        session.currentPermissions!.scopeToSpace(repository.spaceId).hasAnyPermissions() ? createPageSideNavLink("License Usage", links.licenseUsagePage.generateUrl(), true, undefined) : undefined,
        createPageSideNavLink("Maintenance", links.maintenancePage.generateUrl(), undefined, { permission: Permission.AdministerSystem }),
        createPageSideNavLink("Nodes", links.nodesPage.generateUrl(), undefined, { permission: Permission.AdministerSystem }),
        createPageSideNavLink("Performance", links.performancePage.generateUrl(), undefined, { permission: Permission.ConfigureServer }),
        createPageSideNavLink("Settings", links.settingsListPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.ConfigureServer] }),
        createPageSideNavLink("Signing Keys", links.signingKeysPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem] }),
        createPageSideNavLink("SMTP", links.smtpPage.generateUrl(), undefined, { permission: [Permission.ConfigureServer, Permission.AdministerSystem] }),
        createPageSideNavLink("Subscriptions", links.subscriptionsPage.generateUrl(), undefined, { permission: [Permission.SubscriptionView] }),
        createPageSideNavLink(<>Telemetry {telemetryHasBeenIntroduced ? "" : showAsNew && <NewFeatureChip />}</>, links.telemetryPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem] }),
        createPageSideNavLink("Thumbprint", links.thumbprintPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.MachineEdit], wildcard: true }),
        "divider",
        session.currentPermissions!.scopeToSpace(repository.spaceId).hasAnyPermissions()
            ? createPageSideNavLink("Spaces", links.spacesPage.generateUrl(), undefined)
            : createPageSideNavLink("Spaces", links.spacesPage.generateUrl(), undefined, { permission: [Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete] }),
        createPageSideNavLink("Teams", links.teamsPage.generateUrl(), undefined, { permission: [Permission.TeamEdit, Permission.TeamView] }),
        createPageSideNavLink("Test Permissions", links.testPermissionsPage.generateUrl(), undefined, { permission: [Permission.TeamEdit, Permission.UserView] }),
        createPageSideNavLink("Users", links.usersPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.UserView] }),
        !hideAnyAuthenticationProvidersSupportPasswordManagement && isAllowed({ permission: Permission.UserInvite })
            ? createPageSideNavLink("User Invites", links.userInvitesPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.UserInvite] })
            : undefined,
        createPageSideNavLink("User Roles", links.userRolesPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.UserRoleView] }),
    ]);
}
export default ConfigurationLayout;
