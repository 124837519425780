/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Callout } from "@octopusdeploy/design-system-components";
import type { PageAction } from "@octopusdeploy/design-system-components";
import type { ChannelResource, CreateDeploymentResource, DeploymentPromotionTarget, DeploymentPromotionTenant, DeploymentResource, DeploymentSettingsResource, DeploymentTemplateResource, EnvironmentResource, IExecutionResource, ProjectResource, ReleaseResource, TaskResource, TenantResource, Form as PromptVariablesForm, FormElement, LatestReleaseResource, GetBffDeploymentPreview, CreateDeploymentResourceWithOverrides, CreateDeploymentResourceWithChangeRequestSettings, DeploymentResourceWithChangeRequestSettings, ChangeRequestSettings, GetReleaseDetailBffResponse, ProjectContextRepository, GitPersistenceSettings, } from "@octopusdeploy/octopus-server-client";
import { OctopusError, DeploymentMode, GuidedFailureMode, HasGitPersistenceSettings, Permission, ProcessType, TenantedDeploymentMode, getGitRefType, IsDefaultBranch, ChangeRequestSettingsType, PriorityMode, } from "@octopusdeploy/octopus-server-client";
import type { GetDeploymentFreezesResponse } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as _ from "lodash";
import type { Moment } from "moment";
import * as PLimit from "p-limit";
import * as React from "react";
import type { ActionEvent, AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import ActionToggle from "~/areas/projects/components/Releases/Deployments/ActionToggle";
import type { EnvironmentSelection, StaticEnvironmentSelection } from "~/areas/projects/components/Releases/Deployments/EnvironmentAndTenantSelector/EnvironmentSelection";
import { getEnvironmentIds, getStaticEnvironments } from "~/areas/projects/components/Releases/Deployments/EnvironmentAndTenantSelector/EnvironmentSelection";
import PackageDeploymentOptions from "~/areas/projects/components/Releases/Deployments/PackageDeploymentOptions";
import PendingInterruptions from "~/areas/projects/components/Releases/Deployments/PendingInterruptions";
import { useProjectContext } from "~/areas/projects/context";
import { repository, session } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import { createErrorsFromOctopusError } from "~/components/DataBaseComponent/Errors";
import type { ProgressDialogStatus } from "~/components/Dialog/ProgressDialog";
import ProgressDialog from "~/components/Dialog/ProgressDialog";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent/FormBaseComponent";
import matchErrorsToFieldNames from "~/components/FormBaseComponent/matchErrorsToFieldNames";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { PageContent } from "~/components/PageContent/PageContent";
import { hasPermission, isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import { DeploymentModelType } from "../../Runbooks/RunbookRunNowLayout";
import { DeploymentFreezes } from "./DeploymentFreezes";
import EnvironmentAndTenantSelector from "./EnvironmentAndTenantSelector/EnvironmentAndTenantSelector";
import FailureMode from "./FailureMode";
import ItsmChangeRequestSettings from "./Itsm/ItsmChangeRequestSettings";
import type { JiraServiceManagementProjectSettings, ServiceNowProjectSettings } from "./Itsm/getItsmChangeRequestConfiguration";
import { getChangeControlledEnvironments, getEnabledProjectChangeRequestSettings } from "./Itsm/getItsmChangeRequestConfiguration";
import { default as NowOrLater, NowOrLaterEnum } from "./NowOrLater/NowOrLater";
import { OverrideDeploymentFreezeDialog } from "./OverrideDeploymentFreezeDialog";
import PackageDownloadOptions from "./PackageDownloadOptions";
import type { DeploymentMachineInfo } from "./Preview";
import { DeploymentPreview, DeploymentType } from "./Preview";
import PriorityOptions from "./PriorityOptions";
import PromptVariables from "./PromptVariables";
import CurrentVersionMap from "./currentVersionMap";
import type { DeploymentRequestModel } from "./deploymentRequestModel";
import { getActiveFreezes, getTenantsWithFrozenInfo } from "./getActiveFreezes";
import { loadPendingInterruptions } from "./pendingInterruptionUtil";
type RetryDeploymentGoal = {
    previousDeploymentId: string;
};
type NewDeploymentGoal = {
    tenantIds?: string[];
    environmentIds?: string[];
    tenantTags?: string[];
};
export type CreateDeploymentGoal = RetryDeploymentGoal | NewDeploymentGoal;
export function isNewDeploymentGoal(goal: CreateDeploymentGoal): goal is NewDeploymentGoal {
    return !("previousDeploymentId" in goal && Boolean(goal.previousDeploymentId));
}
interface CreateDeploymentPageInternalProps extends CreateDeploymentPageProps {
    project: ProjectResource;
    projectContextRepository: ProjectContextRepository;
    trackAction: AnalyticTrackedActionDispatcher;
}
export type PromotionsMap = {
    [id: string]: DeploymentPromotionTarget | DeploymentPromotionTenant;
};
interface AvailableDeploymentsApiResults {
    allowDeployment: boolean;
    previews: Map<string, GetBffDeploymentPreview>;
    promptVariablesForm: PromptVariablesForm;
    deployments: DeploymentRequestModel[];
}
//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeploymentModel {
}
interface DeploymentCreateState extends OptionalFormBaseComponentState<DeploymentModel> {
    previousDeployment: DeploymentResource;
    nowOrLater: NowOrLaterEnum;
    forcePackageDownload: boolean;
    forcePackageRedeployment: boolean;
    priority: PriorityMode;
    guidedFailureMode: GuidedFailureMode;
    actionIdsToSkip: string[];
    deployments: DeploymentRequestModel[];
    queueTime: Moment;
    queueTimeExpiry: Moment;
    selectedEnvironments: EnvironmentSelection[];
    selectedTenantIds: string[];
    redirectPath?: LinkHref;
    promptVariablesForm: PromptVariablesForm;
    promotionsMap: PromotionsMap;
    template: DeploymentTemplateResource;
    tenantsWithMissingVariables: string[];
    allEnvironments: EnvironmentResource[];
    deploymentFreezes: GetDeploymentFreezesResponse;
    allTenants: TenantResource[];
    pendingInterruptions: Array<TaskResource<any>>;
    releaseVersion: string;
    deploymentSettings?: DeploymentSettingsResource;
    deploymentSettingsMessage?: string;
    release: ReleaseResource;
    releaseDetails: GetReleaseDetailBffResponse;
    channel: ChannelResource;
    currentVersionMap: CurrentVersionMap;
    latestReleases: LatestReleaseResource[];
    previews: Map<string, GetBffDeploymentPreview>;
    previousDeploymentBeingRetried?: DeploymentResource;
    numOfDeploymentsToBeCreated: number | null;
    numOfDeploymentsCreated: number;
    isBulkDeploymentCreationEnabled: boolean;
    showFreezeOverrideDialog: boolean;
    changeRequestSettings?: ChangeRequestSettings[];
    deploymentMode: DeploymentMode;
}
const MaximumInterruptionsToLoad = 10;
class CreateDeploymentPageInternal extends FormBaseComponent<CreateDeploymentPageInternalProps, DeploymentCreateState, DeploymentModel> {
    private buildDeploymentInfoRaceConditioner = new RequestRaceConditioner();
    constructor(props: CreateDeploymentPageInternalProps) {
        super(props);
        const releaseVersion = this.props.releaseVersion;
        const isBulkDeploymentCreationEnabled = session.features?.IsBulkDeploymentCreationEnabled ?? false;
        this.state = {
            previousDeployment: null!,
            tenantsWithMissingVariables: [],
            nowOrLater: NowOrLaterEnum.Now,
            forcePackageDownload: false,
            forcePackageRedeployment: false,
            priority: PriorityMode.LifecycleDefault,
            guidedFailureMode: GuidedFailureMode.EnvironmentDefault,
            actionIdsToSkip: [],
            deployments: [],
            queueTime: null!,
            queueTimeExpiry: null!,
            selectedEnvironments: [],
            selectedTenantIds: [],
            promptVariablesForm: null!,
            promotionsMap: null!,
            template: null!,
            allEnvironments: [],
            deploymentFreezes: null!,
            allTenants: [],
            pendingInterruptions: [],
            releaseVersion,
            latestReleases: [],
            deploymentSettings: null!,
            deploymentSettingsMessage: null!,
            release: null!,
            releaseDetails: null!,
            channel: null!,
            currentVersionMap: null!,
            previews: new Map<string, GetBffDeploymentPreview>(),
            previousDeploymentBeingRetried: null!,
            numOfDeploymentsToBeCreated: null,
            numOfDeploymentsCreated: 0,
            isBulkDeploymentCreationEnabled,
            showFreezeOverrideDialog: null!,
            changeRequestSettings: [],
            deploymentMode: DeploymentMode.Untenanted,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.project;
            const release = await repository.Projects.getReleaseByVersion(project, this.state.releaseVersion);
            const releaseDetails = await repository.Releases.getViewReleaseDetailsBff(release, 0);
            const templatePromise = repository.Releases.getDeploymentTemplate(release);
            const allEnvsPromise = this.loadAllEnvironments();
            const getDeploymentFreezes = isFeatureToggleEnabled("DeploymentFreezeFeatureToggle") ? await repository.DeploymentFreezes.list(project) : { DeploymentFreezes: [] };
            const previousDeployment = isNewDeploymentGoal(this.props.goal) ? null! : await repository.Deployments.get(this.props.goal.previousDeploymentId);
            const allTenantsPromise = this.loadAllTenants(project);
            const channelPromise = repository.Releases.getChannel(release);
            const latestReleases = await repository.Releases.getLatest(project);
            const currentVersionMap = CurrentVersionMap.CreateFromLatestReleases(latestReleases);
            const { deploymentSettings, message: deploymentSettingsMessage } = await this.LoadDeploymentSettings(project, release);
            const isRetry = previousDeployment && !isNewDeploymentGoal(this.props.goal);
            const guidedFailureMode = isRetry ? (previousDeployment.UseGuidedFailure ? GuidedFailureMode.On : GuidedFailureMode.Off) : deploymentSettings?.DefaultGuidedFailureMode ?? GuidedFailureMode.EnvironmentDefault;
            const actionIdsToSkip = isRetry && previousDeployment.SkipActions.length > 0 ? previousDeployment.SkipActions : [];
            const forcePackageDownload = isRetry ? previousDeployment.ForcePackageDownload : deploymentSettings?.ForcePackageDownload ?? false;
            const template = await templatePromise;
            const previousChangeRequestSettings = (previousDeployment as DeploymentResourceWithChangeRequestSettings)?.ChangeRequestSettings;
            this.setState({
                template,
                promotionsMap: this.buildPromotionsMap(template),
                previousDeployment,
                guidedFailureMode,
                actionIdsToSkip,
                tenantsWithMissingVariables: [],
                allEnvironments: await allEnvsPromise,
                deploymentFreezes: getDeploymentFreezes,
                allTenants: await allTenantsPromise,
                deploymentSettings,
                deploymentSettingsMessage,
                release,
                releaseDetails,
                channel: await channelPromise,
                latestReleases,
                currentVersionMap,
                forcePackageDownload,
                previousDeploymentBeingRetried: isRetry ? previousDeployment : null!,
                changeRequestSettings: previousChangeRequestSettings,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async componentDidUpdate(prevProps: CreateDeploymentPageInternalProps, prevState: DeploymentCreateState) {
        if (isFeatureToggleEnabled("LifecyclePhasePriorityDeploymentFeatureToggle")) {
            if (!_.isEqual(prevState.selectedEnvironments, this.state.selectedEnvironments)) {
                const progression = this.state.releaseDetails.ReleaseProgression;
                const selectedEnvironmentIds = getEnvironmentIds(this.state.selectedEnvironments);
                for (const phase of progression.Phases) {
                    const phaseEnvironments = [...phase.AutomaticDeploymentTargets, ...phase.OptionalDeploymentTargets];
                    if (!phase.IsPriorityPhase) {
                        continue;
                    }
                    if (phaseEnvironments.some((e) => selectedEnvironmentIds.includes(e))) {
                        this.onPriorityChanged(true);
                    }
                }
            }
        }
        if (isFeatureToggleEnabled("DeploymentFreezeByTenantFeatureToggle")) {
            if (!_.isEqual(prevState.selectedTenantIds, this.state.selectedTenantIds)) {
                const getDeploymentFreezes = isFeatureToggleEnabled("DeploymentFreezeFeatureToggle") ? await repository.DeploymentFreezes.list(this.props.project, this.state.selectedTenantIds) : { DeploymentFreezes: [] };
                this.setState({
                    deploymentFreezes: getDeploymentFreezes,
                });
            }
        }
    }
    render() {
        if (this.state.redirectPath) {
            return <InternalRedirect to={this.state.redirectPath} push={true}/>;
        }
        // If the user attempts to create deployments and one or more fail, the primary-action becomes "Deploy Unsuccessful"
        // This allows retrying only the failed attempts
        let onSaveLabel: string;
        let onSaveClick: () => Promise<void>;
        const pageActions: PageAction[] = [];
        if (!this.hasFailedAttempts()) {
            onSaveLabel = "Deploy";
            onSaveClick = () => this.prepareDeployment();
        }
        else {
            onSaveLabel = "Retry Unsuccessful";
            onSaveClick = () => this.prepareDeployment(true);
            pageActions.push({ type: "button", label: "Deploy All", buttonType: "secondary", onClick: () => this.prepareDeployment(false, true) });
        }
        const isSaveEnabled = !this.state.busy && this.canDeploy(this.state.selectedEnvironments, this.state.selectedTenantIds);
        const activeFreezes = this.refinedActiveFreezes();
        const deploymentSettings = this.state.deploymentSettings;
        const selectedEnvironmentsWithMissingDynamicInfrastructure = deploymentSettings && deploymentSettings.ConnectivityPolicy && deploymentSettings.ConnectivityPolicy.AllowDeploymentsToNoTargets === false
            ? []
            : this.state.allEnvironments.filter((e) => e.AllowDynamicInfrastructure === false && getEnvironmentIds(this.state.selectedEnvironments).indexOf(e.Id) > -1);
        return (<>
                <LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={onSaveClick} savePermission={{ permission: Permission.DeploymentCreate, environment: "*", tenant: "*", project: this.props.project && this.props.project.Id }} saveText="Deployment started" forceDisableFormSaveButton={!isSaveEnabled} disableDirtyFormChecking={true}>
                    {({ FormContent, createSaveAction }) => (<PageContent header={{
                    title: `Deploy release ${this.state.release ? this.state.release.Version : ""}`,
                    breadcrumbs: [
                        { label: "Releases", pageUrl: links.releasesPage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug }) },
                        ...(this.state.release
                            ? [{ label: this.state.release.Version, pageUrl: links.releasePage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug, releaseVersion: this.state.release.Version }) }]
                            : []),
                    ],
                    primaryAction: createSaveAction({ saveButtonLabel: onSaveLabel }),
                    pageActions,
                }} busy={this.state.busy} errors={this.errors} callout={this.state && this.state.release
                    ? {
                        type: "custom",
                        content: (<>
                                                  {this.state.template && (!this.state.template.PromoteTo || this.state.template.PromoteTo.length === 0) && (<Callout title="Note" type={"warning"}>
                                                          Before you can deploy this release, you need to <InternalLink to={links.infrastructureEnvironmentsPage.generateUrl({ spaceId: this.props.project.SpaceId })}>add an environment</InternalLink>{" "}
                                                          to deploy it to.
                                                      </Callout>)}
                                                  {selectedEnvironmentsWithMissingDynamicInfrastructure.length > 0 && (<Callout title="Dynamic Infrastructure Note" type={"information"}>
                                                          This project allows deployments to be created when there are no deployment targets, but the following environments do not allow dynamic targets to be created. Please note that this may cause
                                                          an error during deployment if you're using <ExternalLink href="EnvironmentDynamicInfrastructure">Dynamic Infrastructure</ExternalLink>.
                                                          <div>You can opt into dynamic infrastructure for a given environment from the link(s) below:</div>
                                                          <div>
                                                              {selectedEnvironmentsWithMissingDynamicInfrastructure.map((env) => (<span>
                                                                      <InternalLink key={env.Id} to={links.infrastructureEnvironmentPage.generateUrl({ spaceId: env.SpaceId, environmentId: env.Id })} openInSelf={false}>
                                                                          {env.Name}
                                                                      </InternalLink>
                                                                      &nbsp;
                                                                  </span>))}
                                                          </div>
                                                      </Callout>)}
                                                  <PendingInterruptions pendingInterruptions={this.state.pendingInterruptions}/>
                                                  {this.state.deployments.length > 1 && this.state.isBulkDeploymentCreationEnabled && (<Callout title="Bulk deployment creation" type={"information"}>
                                                          Deployments will be created in bulk using a server task. You can go to <InternalLink to={links.featuresPage.generateUrl()}>Features</InternalLink> to change bulk deployment creation settings.
                                                      </Callout>)}
                                              </>),
                    }
                    : undefined}>
                            <FormContent>{this.deploymentConfigurationForm()}</FormContent>
                        </PageContent>)}
                </LegacyForm>
                {this.progressDialog()}
                <OverrideDeploymentFreezeDialog show={this.state.showFreezeOverrideDialog} activeFreezes={activeFreezes} allEnvironments={this.state.allEnvironments} allTenants={this.state.allTenants} onClosed={() => this.setState({ showFreezeOverrideDialog: false })} onContinueClick={(reason: string) => this.overrideFreezeAndDeploy(reason)}/>
            </>);
    }
    private progressDialog() {
        const { numOfDeploymentsToBeCreated, numOfDeploymentsCreated } = this.state;
        const singleDeployment = numOfDeploymentsToBeCreated === 1;
        const title = `Creating ${numOfDeploymentsToBeCreated} deployment${singleDeployment ? "" : "s"}`;
        const content = `Please wait while the deployment${singleDeployment ? " is" : "s are"} created.`;
        const progressStatus: ProgressDialogStatus = numOfDeploymentsToBeCreated === null ? { type: "not in progress" } : { type: "in progress", percentComplete: Math.min((numOfDeploymentsCreated / numOfDeploymentsToBeCreated) * 100, 100) };
        return <ProgressDialog title={title} content={content} status={progressStatus}/>;
    }
    private refinedActiveFreezes() {
        let activeFreezes = getActiveFreezes(this.state.deploymentFreezes, this.props.project.Id, getEnvironmentIds(this.state.selectedEnvironments), this.state.selectedTenantIds, this.state.queueTime);
        if (this.state.deploymentMode === DeploymentMode.Untenanted) {
            activeFreezes = activeFreezes.map((freeze) => {
                freeze.TenantEnvironments = freeze.TenantEnvironments.filter((tenant) => !tenant.TenantId);
                return {
                    ...freeze,
                };
            });
        }
        return activeFreezes;
    }
    private deploymentConfigurationForm() {
        const template = this.state.template;
        const selectedEnvironmentIds = getEnvironmentIds(this.state.selectedEnvironments);
        const activeFreezes = this.refinedActiveFreezes();
        const projectChangeRequestSettings = getEnabledProjectChangeRequestSettings(this.state.allEnvironments.filter((e) => selectedEnvironmentIds.indexOf(e.Id) > -1), this.props.project);
        return (<div>
                {this.state && this.state.release && (<div>
                        {this.state.deploymentSettingsMessage && (<Callout title="Some settings may have changed" type={"warning"}>
                                {this.state.deploymentSettingsMessage}
                            </Callout>)}
                        {this.state.deploymentFreezes && this.state.allEnvironments && <DeploymentFreezes activeFreezes={activeFreezes} project={this.props.project} allEnvironments={this.state.allEnvironments} allTenants={this.state.allTenants}/>}
                        {template && (<EnvironmentAndTenantSelector project={this.props.project} template={template} channel={this.state.channel} tenantedDeploymentMode={this.props.project.TenantedDeploymentMode} onSelectionUpdated={this.onSelectionUpdated} onDoingBusyTask={this.doBusyTask} release={this.state.release} latestReleases={this.state.latestReleases} allTenants={this.state.allTenants} allEnvironments={this.state.allEnvironments} goal={isNewDeploymentGoal(this.props.goal) ? this.props.goal : getRetryGoalForSelector(this.props.goal, this.state.previousDeployment)}/>)}

                        {this.state.promptVariablesForm && this.state.promptVariablesForm.Elements.length > 0 && (<PromptVariables form={this.state.promptVariablesForm} onParameterChanged={(variable) => {
                        const promptVariablesForm = { ...this.state.promptVariablesForm };
                        promptVariablesForm.Values[variable.VariableName] = variable.Value;
                        this.setState({ promptVariablesForm });
                    }} processType={ProcessType.Deployment}/>)}

                        <NowOrLater onScheduleDatesSet={this.onDeploymentScheduleChanged} modelType={DeploymentModelType.Deployment}/>

                        {isFeatureToggleEnabled("LifecyclePhasePriorityDeploymentFeatureToggle") && <PriorityOptions priority={this.state.priority} onChange={this.onPriorityChanged}/>}

                        <ActionToggle repository={this.props.projectContextRepository} selectedEnvironmentIds={getEnvironmentIds(this.state.selectedEnvironments)} previews={Array.from(this.state.previews.values())} release={this.state.release} actionIds={this.state.actionIdsToSkip} onActionIdsChanged={this.onActionIdsToSkipChanged} project={this.props.project}/>

                        <FailureMode defaultGuidedFailureMode={this.state.deploymentSettings?.DefaultGuidedFailureMode ?? GuidedFailureMode.EnvironmentDefault} guidedFailureMode={this.state.guidedFailureMode} onModeChanged={(guidedFailureMode) => this.setState({ guidedFailureMode })} modelType={DeploymentModelType.Deployment}/>

                        <PackageDownloadOptions forcePackageDownload={this.state.forcePackageDownload} onOptionChanged={this.onPackageDownloadOptionChanged}/>

                        {this.state.deploymentSettings && this.state.deploymentSettings.DefaultToSkipIfAlreadyInstalled && (<PackageDeploymentOptions forcePackageRedeployment={this.state.forcePackageRedeployment} onChange={this.onPackageReDeploymentOptionChanged}/>)}

                        {projectChangeRequestSettings &&
                    this.state.selectedEnvironments.length > 0 &&
                    projectChangeRequestSettings.map((s) => s && (<ItsmChangeRequestSettings key={s.ExtensionId} context={s} changeRequestSettings={this.state.changeRequestSettings?.filter((crs) => crs.Type === this.getChangeRequestTypeFromExtensionId(s))[0]} onChangeRequestSettingsChanged={this.onChangeRequestSettingsChanged}/>))}

                        {this.state.deployments.length > 0 && (<DeploymentPreview release={this.state.release} getDeploymentPreview={this.getDeploymentPreview} deployments={this.state.deployments} stepActionIdsToSkip={this.state.actionIdsToSkip} tenantedDeploymentMode={this.props.project.TenantedDeploymentMode} promptVariableForm={this.state.promptVariablesForm} onExcludeSpecificMachinesSelected={this.onExcludeSpecificMachinesSelected} onIncludeSpecificMachinesSelected={this.onIncludeSpecificMachinesSelected} onAllTargetsSelected={this.onAllTargetsSelected} tenantsWithMissingVariables={this.state.tenantsWithMissingVariables} onDoingBusyTask={this.doBusyTask} allTenants={getTenantsWithFrozenInfo(this.state.allTenants, this.state.deploymentFreezes, this.props.project.Id, getEnvironmentIds(this.state.selectedEnvironments))} modelType={DeploymentModelType.Deployment}/>)}
                    </div>)}
            </div>);
    }
    private getChangeRequestTypeFromExtensionId(extension: ServiceNowProjectSettings | JiraServiceManagementProjectSettings): ChangeRequestSettingsType {
        switch (extension.ExtensionId) {
            case "servicenow-integration":
                return ChangeRequestSettingsType.ServiceNow;
            case "jiraservicemanagement-integration":
                return ChangeRequestSettingsType.JiraServiceManagement;
            default:
                throw new Error("unknown extension id");
        }
    }
    private getDeploymentPreview = (environmentId: string, tenantId: string) => {
        return this.state.previews.get(`${environmentId || ""}${tenantId || ""}`);
    };
    private canDeploy(selectedEnvironments: EnvironmentSelection[], resultantTenants: string[]): boolean {
        if (!this.props.project) {
            return false;
        }
        if (this.state.deploymentFreezes) {
            const activeFreezes = this.refinedActiveFreezes();
            if (activeFreezes.length > 0 && !hasPermission(Permission.DeploymentFreezeAdminister)) {
                return false;
            }
        }
        const staticEnvironments = getStaticEnvironments(selectedEnvironments);
        const environmentSelected = staticEnvironments.length > 0;
        const tenantSelected = resultantTenants && resultantTenants.length > 0;
        switch (this.props.project.TenantedDeploymentMode) {
            case TenantedDeploymentMode.TenantedOrUntenanted:
                return environmentSelected || tenantSelected;
            case TenantedDeploymentMode.Untenanted:
                return environmentSelected && !tenantSelected;
            case TenantedDeploymentMode.Tenanted:
                return environmentSelected && tenantSelected;
            default:
                throw new Error("TenantedDeploymentMode not recognized");
        }
    }
    private buildPromotionsMap(template: DeploymentTemplateResource) {
        const promotionsMap: PromotionsMap = {};
        _.each(template.PromoteTo, (environmentPromotion) => {
            promotionsMap[environmentPromotion.Id] = environmentPromotion;
        });
        _.each(template.TenantPromotions, (tenantPromotion) => {
            promotionsMap[tenantPromotion.Id] = tenantPromotion;
        });
        return promotionsMap;
    }
    private async prepareDeployment(retry = false, all = false): Promise<void> {
        const activeFreezes = this.refinedActiveFreezes();
        if (activeFreezes.length === 0) {
            const deployments = _.cloneDeep(this.state.deployments);
            const deploymentStrategy = (deployment: DeploymentResource) => repository.Deployments.create(deployment);
            await this.deploy(deploymentStrategy, deployments, retry, all);
            return;
        }
        this.setState({ showFreezeOverrideDialog: true });
    }
    private async overrideFreezeAndDeploy(reason: string, retry = false, all = false): Promise<void> {
        this.setState({ showFreezeOverrideDialog: false });
        const deployments = _.cloneDeep(this.state.deployments);
        if (this.shouldBulkDeploy(this.state.deployments)) {
            for (const deployment of deployments) {
                const overridenDeployment = deployment.request as CreateDeploymentResourceWithOverrides;
                const relevantFreezes = getActiveFreezes(this.state.deploymentFreezes, this.props.project.Id, getEnvironmentIds([deployment.environment]), this.state.selectedTenantIds, this.state.queueTime);
                if (relevantFreezes.length === 0)
                    continue;
                overridenDeployment.OverrideDeploymentFreezeIds = relevantFreezes.map((freeze) => freeze.Id);
                overridenDeployment.OverrideReason = reason;
                deployment.request = overridenDeployment;
            }
        }
        const deploymentStrategy = async (deployment: DeploymentResource) => {
            const relevantFreezes = getActiveFreezes(this.state.deploymentFreezes, this.props.project.Id, [deployment.EnvironmentId], deployment.TenantId ? [deployment.TenantId] : [], deployment.QueueTime);
            const command = { CreateDeploymentCommand: deployment, FreezeIds: relevantFreezes.map((f) => f.Id), Reason: reason };
            const response = await repository.Deployments.createDeploymentFreezeOverride(command);
            return response.Deployment;
        };
        await this.deploy(deploymentStrategy, deployments, retry, all);
    }
    private async deploy(deploymentStrategy: (deployment: DeploymentResource) => Promise<DeploymentResource>, deployments: DeploymentRequestModel[], retry = false, all = false): Promise<void> {
        const ev: ActionEvent = {
            action: Action.Deploy,
            resource: "Deploy Release",
        };
        const gitRef = this.state.release.VersionControlReference?.GitRef;
        if (HasGitPersistenceSettings(this.props.project.PersistenceSettings) && gitRef) {
            ev.isDefaultBranch = IsDefaultBranch(this.props.project.PersistenceSettings, gitRef);
            ev.gitRefType = getGitRefType(gitRef);
        }
        await this.doBusyTask(async () => {
            await this.props.trackAction("Trigger Deployment", ev, async (cb: AnalyticErrorCallback) => {
                const deploymentPromises: any[] = [];
                await this.setUseGuidedFailure(deployments);
                this.setChangeRequestSettings(deployments);
                this.shouldBulkDeploy(deployments) ? await this.performBulkDeployments(deployments) : await this.performDeployments(retry, deployments, deploymentPromises, deploymentStrategy, cb);
            });
        });
    }
    private shouldBulkDeploy(deployments: DeploymentRequestModel[]) {
        return this.state.isBulkDeploymentCreationEnabled && deployments.length > 1;
    }
    private async performBulkDeployments(deploymentRequests: DeploymentRequestModel[]) {
        const formValues = this.state.promptVariablesForm ? this.state.promptVariablesForm.Values : null;
        const deployments = deploymentRequests.map((d) => d.request as CreateDeploymentResourceWithOverrides);
        try {
            const response = await repository.Deployments.createBulkDeployment(deployments, formValues);
            const redirectPath = links.projectTaskDetailsPage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug, taskId: response.TaskId });
            this.setState({ redirectPath: redirectPath });
        }
        catch (ex) {
            const error = createErrorsFromOctopusError(ex);
            error.fieldErrors = error.errors.reduce((prev, current, index) => ({ ...prev, [`error_${index}`]: current }), {});
            this.setValidationErrors(error.message, error.fieldErrors);
        }
    }
    private async performDeployments(retry: boolean, deployments: DeploymentRequestModel[], deploymentPromises: any[], deploymentStrategy: (deployment: DeploymentResource) => Promise<DeploymentResource>, cb: AnalyticErrorCallback) {
        const deploymentsBeingCreated = retry ? this.state.deployments.filter((d) => !(d.response as DeploymentResource).TaskId).length : this.state.deployments.length;
        this.setState({ numOfDeploymentsToBeCreated: deploymentsBeingCreated });
        //Fire off a max of 10 deployment requests at any one time, so we don't bombard the server if there are many environments/tenants
        const throttle = PLimit.default(10);
        const deploymentErrors: Errors[] = [];
        for (const record of deployments) {
            // If retrying, only process previously failed
            if (retry) {
                if (!record.response || !this.isError(record.response)) {
                    continue;
                }
            }
            record.request.FormValues = this.state.promptVariablesForm ? this.state.promptVariablesForm.Values : null;
            deploymentPromises.push(throttle(() => deploymentStrategy(record.request as any)
                .then((deployment) => {
                record.response = deployment;
                this.setState({ numOfDeploymentsCreated: this.state.numOfDeploymentsCreated + 1 });
            })
                .catch((ex) => {
                const error = createErrorsFromOctopusError(ex);
                const arbitraryErrors = error.errors.reduce((prev, current, index) => ({ ...prev, [`error_${index}`]: current }), {});
                error.fieldErrors = { ...arbitraryErrors, ...matchErrorsToFieldNames(ex, this.state.model) };
                deploymentErrors.push(error);
                record.response = ex;
            })));
        }
        await Promise.all(deploymentPromises);
        // Reset number of deployments to close dialog
        this.setState({ numOfDeploymentsToBeCreated: null, numOfDeploymentsCreated: 0 });
        if (deployments.length === 1 && (deployments[0].response as DeploymentResource).TaskId) {
            // If creating a single deployment was successful, navigate to the task details for that deployment
            const redirectPath = links.deploymentDetailsPage.generateUrl({
                spaceId: this.props.project.SpaceId,
                projectSlug: this.props.project.Slug,
                releaseVersion: this.state.release.Version,
                deploymentId: (deployments[0].response as DeploymentResource).Id,
            });
            this.setState({ redirectPath });
        }
        else if (_.every(deployments, (result) => !!(result.response as DeploymentResource).TaskId)) {
            // If creating multiple deployments were all successful, navigate to the task list page filtered
            // to show the created deployment tasks
            const taskIds = _.map(deployments, (result) => (result.response as DeploymentResource).TaskId);
            this.setState({ redirectPath: links.tasksPage.generateUrl({ ids: taskIds, spaces: [this.props.project.SpaceId], includeSystem: false }) });
        }
        else {
            // Otherwise there was at least one error when creating the deployment/s
            if (deploymentErrors.length === 1) {
                this.setValidationErrors(deploymentErrors[0].message, deploymentErrors[0].fieldErrors);
                // If there was a single error then the error details at the top of the page
            }
            else {
                // If there were multiple errors, show a generic message at the top of the page
                // The individual error details will be shown in the deployments section
                this.setValidationErrors(`${deploymentErrors.length} errors occurred while attempting to create the deployments.  See the Deployments section below for the error details.`);
            }
            this.setState({ deployments });
            for (const e of deploymentErrors) {
                cb(e);
            }
        }
    }
    private createDeploymentsForStaticEnvironments(environments: StaticEnvironmentSelection[], tenantIds: string[], promptVariablesForm: PromptVariablesForm) {
        const results = [];
        if (environments.length === 0) {
            return [];
        }
        if (tenantIds.length > 0) {
            for (const tenantId of tenantIds) {
                results.push(this.createDeploymentRequestForStaticEnvironment(environments[0], tenantId, promptVariablesForm));
            }
        }
        else {
            if (this.props.project && this.props.project.TenantedDeploymentMode !== TenantedDeploymentMode.Tenanted) {
                for (const environment of environments) {
                    results.push(this.createDeploymentRequestForStaticEnvironment(environment, null!, promptVariablesForm));
                }
            }
        }
        return results;
    }
    private async loadDeploymentPreviewsForStaticEnvironments(environmentIds: string[], tenantIds: string[]) {
        const map = new Map<string, GetBffDeploymentPreview>();
        let keys: string[] = [];
        let values: GetBffDeploymentPreview[] = [];
        // If tenants have been selected then we use the tenant-environment deployment-previews
        if (tenantIds && tenantIds.length > 0) {
            [keys, values] = await this.getTenantEnvironmentPreviews(environmentIds, tenantIds);
        }
        else {
            environmentIds.filter((environmentId) => this.state.promotionsMap[environmentId]).map((environmentId) => keys.push(environmentId));
            const response = await repository.Releases.getDeploymentPreviewsBff(this.state.release, environmentIds, []);
            values = response.Previews;
        }
        for (let index = 0; index < keys.length; index++) {
            map.set(keys[index], values[index]);
        }
        return map;
    }
    // Returns promises for deployment-previews for the combination of selected tenants and environments
    private async getTenantEnvironmentPreviews(environmentIds: string[], tenantIds: string[]): Promise<[
        string[],
        GetBffDeploymentPreview[]
    ]> {
        const keys: string[] = [];
        const eIds: string[] = [];
        const tIds = _.flatten(tenantIds.map((tenantId) => {
            const dpt = this.state.promotionsMap[tenantId] as DeploymentPromotionTenant;
            return dpt.PromoteTo.filter((tenantEnvironmentPromotion) => environmentIds.includes(tenantEnvironmentPromotion.Id)).map((tenantEnvironmentPromotion) => {
                keys.push(tenantEnvironmentPromotion.Id + tenantId);
                eIds.push(tenantEnvironmentPromotion.Id);
                return tenantId;
            });
        }));
        const response = await repository.Releases.getDeploymentPreviewsBff(this.state.release, [...new Set(eIds)], tIds);
        const values = response.Previews;
        return [keys, values];
    }
    private async LoadDeploymentSettings(project: ProjectResource, release: ReleaseResource): Promise<{
        message?: string;
        deploymentSettings?: DeploymentSettingsResource;
    }> {
        if (!HasGitPersistenceSettings(project.PersistenceSettings)) {
            return { deploymentSettings: await repository.ProjectDeploymentSettings.get(project.Id) };
        }
        const defaultBranch = (project.PersistenceSettings as GitPersistenceSettings).DefaultBranch;
        const releaseGitRef = release.VersionControlReference?.GitRef;
        const FallbackMessageCallback = () => ({ message: `Unable to load deployment settings from repository. Falling back to default deployment settings.` });
        const loadFromDefaultBranch = () => repository.Projects.getBranch(project, defaultBranch)
            .then((gitRef) => repository.ProjectDeploymentSettings.get(project.Id, gitRef.CanonicalName))
            .then((deploymentSettings) => ({ deploymentSettings }));
        if (!releaseGitRef) {
            // If the current release doesn't have a GitRef, but the project is version controlled
            // then the release must have been created before conversion
            // We fall back to the default branch since that should be considered the closest to the "current details" had it not been version controlled.
            return loadFromDefaultBranch().catch(FallbackMessageCallback);
        }
        else {
            // Try to load the project settings from the releases GitRef...
            return repository.Projects.getGitRef(project, releaseGitRef)
                .then((gitRef) => repository.ProjectDeploymentSettings.get(project.Id, gitRef.CanonicalName))
                .then((deploymentSettings) => ({ deploymentSettings }))
                .catch((e) => {
                // ...But if the project settings couldn't be loaded for the releases GitRef
                if (e instanceof OctopusError && e.StatusCode === 404) {
                    // ... Try load the default branch for this project...
                    return loadFromDefaultBranch().then((defaultBranchProcess) => ({
                        ...defaultBranchProcess,
                        message: `Unable to load original branch at ${releaseGitRef}. Using deployment settings found on the default branch (${defaultBranch})`,
                    }));
                }
                return FallbackMessageCallback();
            })
                .catch(FallbackMessageCallback); // If all else fails fall back to some sensible defaults
        }
    }
    private loadFormDetails(previews: Map<string, GetBffDeploymentPreview>) {
        const form: PromptVariablesForm = { Elements: [], Values: {} };
        previews.forEach((preview: GetBffDeploymentPreview) => {
            if (!preview || !preview.Form) {
                return;
            }
            if (preview.Form.Values) {
                _.each(preview.Form.Values, (v, k) => {
                    form.Values[k] = v;
                });
            }
            if (preview.Form.Elements) {
                preview.Form.Elements.forEach((c) => {
                    if (!form.Elements.find((e: FormElement) => {
                        return e.Name === c.Name;
                    })) {
                        form.Elements.push(c);
                    }
                });
            }
        });
        return form;
    }
    private createDeploymentRequestForStaticEnvironment(environment: StaticEnvironmentSelection, tenantId: string, promptVariablesForm: PromptVariablesForm): DeploymentRequestModel {
        const isRetryingInThisScope = this.state.previousDeploymentBeingRetried && this.state.previousDeploymentBeingRetried.EnvironmentId === environment.environmentId && this.state.previousDeploymentBeingRetried.TenantId === tenantId;
        const specificMachineIds = isRetryingInThisScope && this.state.previousDeploymentBeingRetried!.SpecificMachineIds.length > 0 ? this.state.previousDeploymentBeingRetried!.SpecificMachineIds : [];
        const excludeMachineIds = isRetryingInThisScope && this.state.previousDeploymentBeingRetried!.ExcludedMachineIds.length > 0 ? this.state.previousDeploymentBeingRetried!.ExcludedMachineIds : [];
        const request: CreateDeploymentResource = {
            ReleaseId: this.state.release.Id,
            EnvironmentId: environment.environmentId,
            ProjectId: this.props.project.Id,
            TenantId: tenantId,
            SkipActions: this.state.actionIdsToSkip,
            QueueTime: this.state.queueTime,
            QueueTimeExpiry: this.state.queueTimeExpiry,
            FormValues: promptVariablesForm ? promptVariablesForm.Values : null,
            ForcePackageDownload: this.state.forcePackageDownload,
            UseGuidedFailure: false,
            SpecificMachineIds: specificMachineIds,
            ExcludedMachineIds: excludeMachineIds,
            ForcePackageRedeployment: this.state.forcePackageRedeployment,
            Priority: this.state.priority,
        };
        return {
            tenantId,
            environment,
            request,
            currentVersion: this.state.currentVersionMap.getCurrentRelease(environment.environmentId, tenantId)!,
        };
    }
    private async getAvailableDeploymentsFromApi(environments: StaticEnvironmentSelection[], tenantIds: string[], tenantTagsUsed: boolean): Promise<AvailableDeploymentsApiResults> {
        const previews = await this.loadDeploymentPreviewsForStaticEnvironments(environments.map((e) => e.environmentId), tenantIds);
        const promptVariablesForm = this.loadFormDetails(previews);
        // If the selected tenant-tags did not match any tenants, then we want to ensure checkCanDeploy is false and that
        // there are no deployments created
        if (tenantTagsUsed && tenantIds.length === 0) {
            return {
                previews,
                allowDeployment: false,
                promptVariablesForm,
                deployments: [],
            };
        }
        const deployments = this.createDeploymentsForStaticEnvironments(environments, tenantIds, promptVariablesForm);
        return {
            previews,
            allowDeployment: true,
            promptVariablesForm,
            deployments,
        };
    }
    private onSelectionUpdated = async (environments: EnvironmentSelection[], tenantIds: string[], tenantTagsUsed: boolean, tenantsWithMissingVariables: string[], deploymentMode: DeploymentMode) => {
        const previews = new Map<string, GetBffDeploymentPreview>();
        const deployments: DeploymentRequestModel[] = [];
        const staticEnvironments = getStaticEnvironments(environments);
        const environmentIds = getEnvironmentIds(environments);
        await this.doBusyTask(async () => {
            if (staticEnvironments.length > 0) {
                await this.buildDeploymentInfoRaceConditioner.avoidStaleResponsesForRequest(this.getAvailableDeploymentsFromApi(staticEnvironments, tenantIds, tenantTagsUsed), (apiResults) => {
                    if (apiResults.allowDeployment) {
                        apiResults.previews.forEach((preview, key) => {
                            previews.set(key, preview);
                        });
                        deployments.push(...apiResults.deployments);
                    }
                });
            }
            let pendingInterruptions: Array<TaskResource<any>> = [];
            // We only load interruptions if the number of deployments is low, see https://github.com/OctopusDeploy/Issues/issues/4415
            if (deployments.length < MaximumInterruptionsToLoad) {
                pendingInterruptions = await loadPendingInterruptions(this.props.project.Id, deployments.map((d) => {
                    return { EnvironmentId: d.request.EnvironmentId, TenantId: d.tenantId };
                }));
            }
            this.setState({
                previews: previews,
                selectedEnvironments: environments,
                selectedTenantIds: tenantIds,
                deployments: deployments,
                promptVariablesForm: this.loadFormDetails(previews),
                pendingInterruptions: pendingInterruptions || [],
                actionIdsToSkip: environmentIds.length === 0 ? [] : this.state.actionIdsToSkip,
                tenantsWithMissingVariables: tenantsWithMissingVariables,
                deploymentMode,
            });
        }, { timeOperationOptions: timeOperationOptions.for("GetPreview") });
    };
    private setChangeRequestSettings(deploymentRequests: DeploymentRequestModel[]) {
        const selectedEnvironmentIds = getEnvironmentIds(this.state.selectedEnvironments);
        const changeControlledEnvironments = getChangeControlledEnvironments(this.state.allEnvironments.filter((e) => selectedEnvironmentIds.indexOf(e.Id) > -1));
        if (deploymentRequests.length > 0 && changeControlledEnvironments.length > 0) {
            const deploymentsByEnvironment = _.groupBy(deploymentRequests, (x) => x.request.EnvironmentId);
            changeControlledEnvironments
                .map((e) => e.Id)
                .forEach((environmentId) => deploymentsByEnvironment[environmentId].map((d) => d.request as CreateDeploymentResourceWithChangeRequestSettings).forEach((deployment) => (deployment.ChangeRequestSettings = this.state.changeRequestSettings ?? [])));
        }
    }
    private async setUseGuidedFailure(deploymentRequests: DeploymentRequestModel[]) {
        const mode = this.state.guidedFailureMode;
        if (deploymentRequests.length > 0) {
            if (mode === GuidedFailureMode.EnvironmentDefault) {
                const deploymentsByEnvironment = _.groupBy(deploymentRequests, (x) => x.request.EnvironmentId);
                const environmentIds = _.chain(deploymentRequests)
                    .map((x) => x.request.EnvironmentId)
                    .uniq()
                    .value();
                for (const environmentId of environmentIds) {
                    const environmentType = deploymentsByEnvironment[environmentId][0].environment.type;
                    if (environmentType === "Static") {
                        const environment = await repository.Environments.get(environmentId);
                        for (const deployment of deploymentsByEnvironment[environmentId]) {
                            deployment.request.UseGuidedFailure = environment.UseGuidedFailure;
                        }
                    }
                }
            }
            else {
                for (const deployment of deploymentRequests) {
                    deployment.request.UseGuidedFailure = mode === GuidedFailureMode.On;
                }
            }
        }
    }
    private onChangeRequestSettingsChanged = (updatedChangeRequestSettings: ChangeRequestSettings) => {
        let changeRequestSettings = _.cloneDeep(this.state.changeRequestSettings) ?? [];
        changeRequestSettings = changeRequestSettings.map((c) => (c.Type === updatedChangeRequestSettings.Type ? updatedChangeRequestSettings : c));
        if (changeRequestSettings.findIndex((c) => c.Type === updatedChangeRequestSettings.Type) < 0) {
            changeRequestSettings.push(updatedChangeRequestSettings);
        }
        this.setState({ changeRequestSettings });
    };
    private onPackageDownloadOptionChanged = (forcePackageDownload: boolean) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach((deployment) => (deployment.request.ForcePackageDownload = forcePackageDownload));
        this.setState({ deployments, forcePackageDownload });
    };
    private onPackageReDeploymentOptionChanged = (forcePackageRedeployment: boolean) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach((deployment) => (deployment.request.ForcePackageRedeployment = forcePackageRedeployment));
        this.setState({ deployments, forcePackageRedeployment });
    };
    private onPriorityChanged = (p: boolean) => {
        const deployments = _.cloneDeep(this.state.deployments);
        const priority = p ? PriorityMode.On : PriorityMode.Off;
        deployments.forEach((deployment) => (deployment.request.Priority = priority));
        this.setState({ deployments, priority });
    };
    private onDeploymentScheduleChanged = (queueTime: Moment, queueTimeExpiry: Moment) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach((deployment) => {
            deployment.request.QueueTime = queueTime;
            deployment.request.QueueTimeExpiry = queueTimeExpiry;
        });
        this.setState({ deployments, queueTime, queueTimeExpiry });
    };
    private onActionIdsToSkipChanged = (excludedActionIdsToSkip: string[]) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach((deployment) => (deployment.request.SkipActions = excludedActionIdsToSkip));
        this.setState({ deployments, actionIdsToSkip: excludedActionIdsToSkip });
    };
    private onExcludeSpecificMachinesSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, machineInfo.machineIds, []);
    };
    private async loadAllEnvironments() {
        return repository.Environments.all();
    }
    private async loadAllTenants(project: ProjectResource) {
        if (project && (project.TenantedDeploymentMode === TenantedDeploymentMode.Tenanted || project.TenantedDeploymentMode === TenantedDeploymentMode.TenantedOrUntenanted)) {
            return isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : [];
        }
        return [];
    }
    private onIncludeSpecificMachinesSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, [], machineInfo.machineIds);
    };
    private onAllTargetsSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, [], []);
    };
    private setTargetMachineIds = (deploymentType: DeploymentType, targetId: string, excludedMachineIds: string[], specificMachineIds: string[]) => {
        const deployments = _.cloneDeep(this.state.deployments);
        const deployment = deploymentType === DeploymentType.Tenant ? deployments.find((x) => x.tenantId === targetId) : deployments.find((x) => x.request.EnvironmentId === targetId);
        deployment!.request.ExcludedMachineIds = excludedMachineIds;
        deployment!.request.SpecificMachineIds = specificMachineIds;
        this.setState({ deployments });
    };
    private hasFailedAttempts(): boolean {
        return _.find(this.state.deployments, (deployment) => deployment.response && this.isError(deployment.response)) !== undefined;
    }
    private isError(response: IExecutionResource | OctopusError): response is OctopusError {
        return (response as OctopusError).ErrorMessage !== undefined;
    }
    static displayName = "CreateDeploymentPageInternal";
}
function getRetryGoalForSelector(goal: RetryDeploymentGoal, previousDeployment: DeploymentResource) {
    if (goal.previousDeploymentId !== previousDeployment.Id) {
        //This shouldn't really ever happen
        throw new Error("Invalid deployment run resource provided for environment selector retry goal");
    }
    return { previousDeployment };
}
interface CreateDeploymentPageProps {
    project: ProjectResource;
    releaseVersion: string;
    goal: CreateDeploymentGoal;
}
export function CreateDeploymentPage(props: CreateDeploymentPageProps) {
    const projectContext = useProjectContext();
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(projectContext.state.model.Id);
    return <CreateDeploymentPageInternal {...props} project={props.project} projectContextRepository={projectContext.state.projectContextRepository} trackAction={trackAction}/>;
}
