import { css } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { CodeIcon, KubernetesIcon } from "@octopusdeploy/design-system-icons";
import { borderRadius, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { TaskState, ActivityElement, KubernetesTaskResourceStatusResource, KubernetesStepStatus } from "@octopusdeploy/octopus-server-client";
import React, { useMemo, useState } from "react";
import type { StepWithKubernetesAction } from "~/areas/tasks/components/Task/K8sStatus/StepWithKubernetesAction";
import { KubernetesStepsLayout } from "~/areas/tasks/components/Task/Kubernetes/KubernetesStepsLayout";
import type { FilterSection } from "~/components/AdvancedFilterLayout/index";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/index";
import FilterSearchBox from "~/components/FilterSearchBox/index";
import Select from "~/primitiveComponents/form/Select/Select";
export type KubernetesDetailsBaseProps = {
    steps: StepWithKubernetesAction[];
    resourceStatus: KubernetesTaskResourceStatusResource;
    activityLogs: Pick<ActivityElement, "Children">[];
    projectId: string | undefined;
    taskState: TaskState | undefined;
    deploymentId: string | undefined;
    runbookRunId: string | undefined;
};
type KubernetesDetailsProps = KubernetesDetailsBaseProps & {
    activeView: KubernetesDetailsStepView;
    setActiveView: (view: KubernetesDetailsStepView) => void;
};
export type KubernetesDetailsFilter = {
    name?: string;
    namespace?: string;
    targetName?: string;
};
export const clusterScopedNamespaceFilter = "Cluster-scoped";
const styles = {
    toggleButtonContainer: css({
        display: "flex",
        flexDirection: "row",
        padding: space[4],
        background: themeTokens.color.background.secondary.default,
        borderRadius: borderRadius["medium"],
        gap: space[4],
    }),
};
export type KubernetesDetailsStepView = "Manifests" | "Snapshot";
export const KubernetesDetailsLayout = (props: KubernetesDetailsProps) => {
    const { activeView, setActiveView, ...rest } = props;
    const [filter, setFilter] = useState<KubernetesDetailsFilter>({});
    const getViewToggleButtons = () => [
        <div className={styles.toggleButtonContainer}>
            <Button label={"Applied Manifests"} leftIcon={<CodeIcon size={20}/>} importance={activeView === "Manifests" ? "loud" : "quiet"} onClick={() => setActiveView("Manifests")}/>
            <Button label={"Object Snapshot"} leftIcon={<KubernetesIcon size={20}/>} importance={activeView === "Snapshot" ? "loud" : "quiet"} onClick={() => setActiveView("Snapshot")}/>
        </div>,
    ];
    const updateFilter = (changedFilters: Partial<KubernetesDetailsFilter>) => {
        setFilter((prev) => ({
            ...prev,
            ...changedFilters,
        }));
    };
    const filterSections: FilterSection[] = useMemo(() => {
        const filters: React.ReactNode[] = [];
        const statuses = props.resourceStatus.KubernetesStepsStatus;
        const distinctListOfItems = (filter: (kss: KubernetesStepStatus) => string[]) => statuses
            .flatMap(filter)
            .filter((value, index, array) => array.indexOf(value) === index)
            .map((value) => ({ value, text: value }));
        const targetNames = distinctListOfItems((kss) => kss.KubernetesObjects.map((o) => o.ClusterName));
        if (targetNames.length > 1) {
            filters.push(<Select value={filter.targetName} onChange={(targetName) => updateFilter({ targetName })} items={targetNames} allowClear={true} label="Filter by target" placeholder="All targets"/>);
        }
        const namespaceNames = distinctListOfItems((kss) => kss.KubernetesObjects.map((o) => o.Namespace || clusterScopedNamespaceFilter));
        if (namespaceNames.length > 1) {
            filters.push(<Select value={filter.namespace} onChange={(namespace) => updateFilter({ namespace })} items={namespaceNames} allowClear={true} label="Filter by namespace" placeholder="All namespaces"/>);
        }
        //if we have no advanced filters, don't show anything
        if (filters.length === 0) {
            return [];
        }
        return [
            {
                render: <div>{filters}</div>,
            },
        ];
    }, [filter, props.resourceStatus]);
    return (<AdvancedFilterLayout filterSections={filterSections} additionalHeaderFilters={[<FilterSearchBox placeholder="Filter by name..." value={filter.name} onChange={(name: string) => updateFilter({ name })}/>]} filter={filter} defaultFilter={{}} onFilterReset={() => setFilter({})} additionalHeaderControls={getViewToggleButtons()} renderContent={() => <KubernetesStepsLayout {...rest} activeView={activeView} filter={filter}/>}/>);
};
