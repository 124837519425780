import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { Pagination, SearchInput, ListItemContent, ListItemLink, List } from "@octopusdeploy/design-system-components";
import { useQuery } from "@octopusdeploy/octopus-react-client";
import type { LibraryVariableSetResource } from "@octopusdeploy/octopus-server-client";
import { Permission, VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import { links, type QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { useCallback } from "react";
import * as React from "react";
import { AddVariableSetDialogLayout } from "~/areas/library/components/VariableSets/AddVariableSetDialogLayout";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import MarkdownDescription from "~/components/MarkdownDescription";
import { NoResults } from "~/components/NoResults/NoResults";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useDebouncedExternalState } from "~/hooks/useDebouncedExternalState";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
interface VariableSetsPageProps {
    spaceId: string;
    queryParams: VariableSetsPageQueryParams;
    setQueryParams: QueryParamValuesSetter<VariableSetsPageQueryParams>;
}
export interface VariableSetsPageQueryParams {
    variableSetName: string;
    page: number | undefined;
    pageSize: number | undefined;
}
const pageTitle = "Variable Sets";
export function VariableSetsPage({ spaceId, queryParams, setQueryParams }: VariableSetsPageProps) {
    const addVariableSetDialogControls = useDialogTrigger();
    const [debouncedVariableSetName, setDebouncedVariableSetName] = useDebouncedExternalState(queryParams.variableSetName, (value) => setQueryParams({ variableSetName: value }), 200);
    const { result: variableSets } = usePagedVariableSetsQuery(queryParams);
    const addVariableSetPageAction: PrimaryPageAction = {
        type: "button",
        hasPermissions: isAllowed({ permission: Permission.LibraryVariableSetCreate }),
        label: "Add Variable Set",
        onClick: addVariableSetDialogControls.openDialog,
    };
    const onPageChange = useCallback((page: number) => {
        setQueryParams({ ...queryParams, page });
    }, [queryParams, setQueryParams]);
    const onPageSizeChange = useCallback((pageSize: number) => {
        setQueryParams({ ...queryParams, pageSize: pageSize, page: 1 });
    }, [queryParams, setQueryParams]);
    if (!variableSets) {
        return <PageContent header={{ title: pageTitle }}>{null}</PageContent>;
    }
    const hideBottomPagination = variableSets.Items.length < 20;
    return (<PageContent header={{ title: pageTitle, primaryAction: addVariableSetPageAction }} filters={{ inputs: [<SearchInput value={debouncedVariableSetName} onChange={setDebouncedVariableSetName} accessibleName={"Search by tag set name"} placeholder={"Filter by name..."}/>] }} pagination={{
            ui: (<Pagination label="Tag Sets" totalResults={variableSets.TotalResults} itemsPerPageOptions={[30, 50, 100]} selectedItemsPerPage={variableSets.ItemsPerPage} currentPage={queryParams.page ?? 1} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange}/>),
            placement: hideBottomPagination ? "top" : "topAndBottom",
        }}>
            <Dialog open={addVariableSetDialogControls.isOpen}>
                <AddVariableSetDialogLayout spaceId={spaceId}/>
            </Dialog>
            <List items={variableSets.Items} empty={<NoResults />} renderRow={({ item: variableSet }) => <LibraryVariableSetListItem variableSet={variableSet}/>} rowKey={(item) => item.Id}/>
        </PageContent>);
}
function usePagedVariableSetsQuery(queryParams: VariableSetsPageQueryParams) {
    return useQuery((repository) => {
        const pageSize = queryParams.pageSize ?? 30;
        return repository.LibraryVariableSets.list({
            skip: ((queryParams.page ?? 1) - 1) * pageSize,
            take: pageSize,
            partialName: queryParams.variableSetName,
            contentType: VariableSetContentType.Variables,
        });
    }, [queryParams.page, queryParams.pageSize, queryParams.variableSetName], "Get paginated variable sets");
}
interface LibraryVariableSetListItemProps {
    variableSet: LibraryVariableSetResource;
}
function LibraryVariableSetListItem({ variableSet }: LibraryVariableSetListItemProps) {
    return (<ListItemLink href={links.editVariableSetPage.generateUrl({ spaceId: variableSet.SpaceId, variableSetId: variableSet.Id })}>
            <ListItemContent>
                <ListTitle>{variableSet.Name}</ListTitle>
                <MarkdownDescription markup={variableSet.Description}/>
            </ListItemContent>
        </ListItemLink>);
}
